/**
Call mobile app api (aka "bridge")
Relying on presence of the api objects, no platform detection
*/

const MobileApi = {

  // must use global object
  getAndroidBridge() {
    if (typeof StoryJsInterface !== 'undefined') {
      // eslint-disable-next-line no-undef
      return StoryJsInterface;
    }
    return null;
  },

  getIosBridge() {
    if (typeof window === 'undefined') {
      return null;
    }
    const { webkit } = window;
    if (typeof webkit !== 'undefined') {
      return webkit;
    }
    return null;
  },

  trackPageView(message) {
    // Android
    const androidBridge = this.getAndroidBridge();
    if (androidBridge) {
      try {
        androidBridge.trackPageView(message);
        return 'android';
      } catch (e) {
        return e;
      }
    }

    // IOS
    const iosBridge = this.getIosBridge();
    if (iosBridge) {
      try {
        iosBridge.messageHandlers.trackPageView.postMessage(message);
        return 'ios';
      } catch (e) {
        return e;
      }
    }

    return null;
  },

  viewVideo(message) {
    // Android
    const androidBridge = this.getAndroidBridge();
    if (androidBridge) {
      try {
        androidBridge.viewVideo(message);
        return 'android';
      } catch (e) {
        return e;
      }
    }

    // IOS
    const iosBridge = this.getIosBridge();
    if (iosBridge) {
      try {
        iosBridge.messageHandlers.viewVideo.postMessage(message);
        return 'ios';
      } catch (e) {
        return e;
      }
    }

    return null;
  },
};

export default MobileApi;
