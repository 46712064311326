import React from 'react';
import getConfig from 'next/config';

import 'assets/javascripts/iconfont';

const fontFormatsByExt = {
  woff: 'woff',
  woff2: 'woff2',
};

function getIconfontSrcsFromPaths(iconfontAssetPaths) {
  return iconfontAssetPaths.map((path) => {
    const [, extension] = (/\.(\w+)$/.exec(path) || []);
    const url = `url(${path})`;
    const format = extension
      ? ` format("${fontFormatsByExt[extension]}")`
      : '';
    return `${url}${format}`;
  }).join(', ');
}

function IconfontStyleTag() {
  const { publicRuntimeConfig: { iconfontAssetPaths = [] } } = getConfig();
  const fontSrcs = getIconfontSrcsFromPaths(iconfontAssetPaths);

  const styleContent = `
    @font-face {
      font-family: iconfont;
      src: ${fontSrcs};
      font-display: swap;
    }
`;

  return fontSrcs
    ? (
      <style dangerouslySetInnerHTML={{ __html: styleContent.replace(/\s/g, '') }} />
    )
    : null;
}

export default IconfontStyleTag;
