import getConfig from 'next/config';

export const verticalThemeNameMap = {
  noticias: 'telemundonoticias',
  deportes: 'telemundodeportes',
  localstationdeportes: 'telemundolocalstationdeportes',
  shows: 'telemundoshows',
  entretenimiento: 'telemundoentretenimiento',
};

export function getThemesCssPath(vertical, pageType) {
  const {
    publicRuntimeConfig: {
      dev,
      themesCssManifest,
      assetPublicPath,
    },
  } = getConfig();

  const themeName = verticalThemeNameMap[vertical] || vertical;

  // In dev mode, return null since the assets will probably not have been built
  // at this point in code execution. In dev mode it uses a webpack style loader
  // which results in no CSS bundles being created. Also in dev mode, we use a
  // dynamic import for the themed styles anyway.
  // We also want to bail early if there's no themesCssManifest or themeName for
  // whatever reason (probably an error state)
  if (dev || !themesCssManifest || !themeName) return null;

  const themeIndexManifestPath = themesCssManifest[themeName];
  const themeIndexFilePath = themeIndexManifestPath
    && `${assetPublicPath}${themeIndexManifestPath}`;

  const themePageManifestPath = themesCssManifest[`${themeName}/${pageType}`];
  const themePageFilePath = themePageManifestPath
    && `${assetPublicPath}${themePageManifestPath}`;

  // return page-specific file path if it exists, otherwise default for theme
  return themePageFilePath || themeIndexFilePath || null;
}
