/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import classNames from 'classnames';
import getConfig from 'next/config';
import { connect } from 'react-redux';

import ErrorMetadata from 'components/PageMetadata/ErrorMetadata';
import ErrorMessage from 'components/ErrorMessage';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import IconfontStyleTag from 'components/IconfontStyleTag';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import globalContainerStyles from './globalContainerStyles.module.scss';

const { publicRuntimeConfig: envVars } = getConfig();

const ENABLE_ERROR_PAGE_PREVIEW = envVars.ENABLE_ERROR_PAGE_PREVIEW === 'true';

const PAGE_TYPES = {
  403: 'Forbidden',
  404: 'NotFound',
  500: 'InternalServerError',
};

/**
 * Maps the state to props for the ErrorPage component.
 * @param {object} state - The state object from the Redux store.
 * @param {object} state.shared - The shared state object.
 * @returns {object} The mapped props.
 */
const mapStateToProps = ({
  shared,
}) => ({
  vertical: shared.vertical,
  isChromeless: shared.isChromeless,
});

export const navbarConfig = {
  /**
   * Determines the theme based on the vertical.
   * @param {object} params - The parameter object.
   * @param {string} params.vertical - The vertical type.
   * @returns {string} The theme type.
   */
  theme: ({ vertical }) => {
    switch (vertical) {
      case 'telemundo':
      case 'deportes':
      case 'localstationdeportes':
      case 'noticias':
      case 'entretenimiento':
      case 'shows':
      case 'news':
      case 'select':
      case 'msnbc':
        return NAVBAR_THEME.VERTICAL;
      default:
        return NAVBAR_THEME.LIGHT;
    }
  },
};

/**
 * Ensures only supported status codes are used
 * @param statusCode
 */
export const validatedStatusCode = (statusCode) => {
  if (Object.keys(PAGE_TYPES).includes(`${statusCode}`)) {
    return statusCode;
  }

  return 500;
};

/**
 * ErrorPage component to display error messages based on status code.
 * @param {object} props - The component props.
 * @param {number} props.statusCode - The HTTP status code.
 * @param {string} props.vertical - The vertical type.
 * @param {object} props.layout - The layout configuration.
 * @param {boolean} props.isChromeless - Flag to determine if the page is chromeless.
 */
function ErrorPage({
  statusCode, vertical, layout, isChromeless,
}) {
  /**
   * Error component to display error metadata and message.
   * @returns {React.ReactElement} The rendered error component.
   */
  const Error = () => (
    <>
      <Head>
        <IconfontStyleTag />
      </Head>
      <ThemesCssBundle vertical={vertical} />

      <div>
        <ErrorMetadata pageType={PAGE_TYPES[statusCode] || PAGE_TYPES[500]} />
        <ErrorMessage code={statusCode} vertical={vertical} />
      </div>
    </>
  );

  const renderHeaderAndFooter = !isChromeless && Object.keys(layout ?? {}).length > 0;

  /**
   * Renders the error page with or without header and footer based on the layout.
   * @param {React.Component} Component - The error component to render.
   * @returns {React.ReactElement} The rendered error page.
   */
  const renderErrorPage = (Component) => {
    // Remove marquees on all Error Pages
    const layoutWithoutMarquees = {
      ...layout,
      marquees: [],
    };
    if (renderHeaderAndFooter) {
      return (
        <HeaderAndFooter layout={layoutWithoutMarquees} vertical={vertical}>
          <Component />
        </HeaderAndFooter>
      );
    }

    return <Component />;
  };

  return (
    <div className={classNames(globalContainerStyles.container)} data-testid="error-page">
      {renderErrorPage(Error)}
      <AnalyticsLaunchScripts />
    </div>
  );
}

ErrorPage.propTypes = {
  isChromeless: PropTypes.bool.isRequired,
  statusCode: PropTypes.number.isRequired,
  vertical: PropTypes.string.isRequired,
  layout: servicesLayoutPropType.isRequired,
};


/**
 * Fetches initial props for the ErrorPage component.
 * @param {object} context - The context object.
 * @param {object} context.req - The request object.
 * @param {object} context.res - The response object.
 * @param {object} context.err - The error object.
 * @returns {Promise<object>} The initial props.
 */
ErrorPage.getInitialProps = async ({
  req, res, err,
}) => {
  let previewStatusCode;
  if (ENABLE_ERROR_PAGE_PREVIEW) {
    previewStatusCode = req?.params?.statusCode && parseInt(req?.params?.statusCode, 10);
  }

  const resStatusCode = previewStatusCode || res?.statusCode;
  const errStatusCode = err?.statusCode;
  const { layout } = res.locals;

  return {
    statusCode: validatedStatusCode(resStatusCode || errStatusCode),
    layout,
  };
};

export default navbar(navbarConfig)(
  connect(mapStateToProps)(ErrorPage),
);

export { ErrorPage as UnwrappedErrorPage };
