import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import verticalLink from 'lib/verticalLink';
import { getDisplayNameForVertical } from 'lib/vertical';

import './styles.themed.scss';

const ErrorMessage = ({ vertical, code }) => {
  const { t } = useTranslation();

  const translatedHeadline = t(`ERROR_H1_${code}`);

  const link = (
    <a href={`/${verticalLink(vertical)}`} className="error-message__text--branded">
      {getDisplayNameForVertical(vertical)}
    </a>
  );

  // TODO: Figure out how to do this natively via react-i18next.
  const translatedText = t(`ERROR_TEXT_${code}`).split('{{link}}');
  translatedText.splice(1, 0, link);

  return (
    <div className="error-message">
      <div className="error-message__headline-container">
        <h1 className="error-message__headline">{translatedHeadline}</h1>
        <div className="error-message__headline-pattern" />
      </div>
      <div className="error-message__text-container">
        <p className="error-message__text">{React.Children.toArray(translatedText)}</p>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  vertical: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
};

export default ErrorMessage;
