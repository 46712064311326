import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { getThemesCssPath, verticalThemeNameMap } from 'lib/getThemesCssPath';

export function ThemesCssBundle({ vertical }) {
  const themesCssPath = getThemesCssPath(vertical);

  let ThemesCssComponent;
  if (__DEVELOPMENT__) { // Use compile time environment variable to enable tree shaking
    if (vertical) {
      const verticalThemeName = verticalThemeNameMap[vertical] || vertical;

      // eslint-disable-next-line import/no-dynamic-require, global-require, import/no-dynamic-require
      ThemesCssComponent = require(`../../../pages/themes/${verticalThemeName}`)
        .default;
    }
  }

  return (
    <>
      <Head>
        {themesCssPath && (
          <>
            <link
              href={themesCssPath}
              rel="preload"
              as="style"
              key={`preload-stylesheet-${themesCssPath}`}
            />
            <link href={themesCssPath} rel="stylesheet" key={`stylesheet-${themesCssPath}`} />
          </>
        )}
      </Head>
      {ThemesCssComponent && <ThemesCssComponent />}
    </>
  );
}

ThemesCssBundle.propTypes = {
  vertical: PropTypes.string.isRequired,
};
