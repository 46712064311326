import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import { VerticalContext } from 'lib/ContextTypes/vertical';

const iconMap = {
  entretenimiento: 'telemundo',
  msnbc: 'msnbc',
  news: 'nbcnews',
  noticias: 'telemundo',
  deportes: 'telemundo',
  select: 'select',
  shows: 'telemundo',
  telemundo: 'telemundo',
  today: 'today',
};

export const constructJSONLD = ({ pageType, vertical }) => (JSON.stringify({
  '@context': {
    '@vocab': 'http://schema.org',
    pageType: {
      '@id': 'Text',
      '@type': '@id',
    },
    vertical: {
      '@id': 'Text',
      '@type': '@id',
    },
    subVertical: {
      '@id': 'Text',
      '@type': '@id',
    },
    section: {
      '@id': 'Text',
      '@type': '@id',
    },
    subSection: {
      '@id': 'Text',
      '@type': '@id',
    },
    packageId: {
      '@id': 'Text',
      '@type': '@id',
    },
    sponsor: {
      '@id': 'Text',
      '@type': '@id',
    },
  },
  '@type': 'Dataset',
  pageType,
  vertical,
  subVertical: '',
  section: '',
  subSection: '',
  packageId: '',
  sponsor: '',
}));

/**
 *
 * @param {VerticalType} vertical
 * @param {string} type
 * @returns {string}
 */
// eslint-disable-next-line max-len
const getIconLink = (vertical, type) => `https://nodeassets.nbcnews.com/cdnassets/projects/ramen/favicon/${iconMap[vertical] || iconMap.news}/all-other-sizes-PNG.ico/${type}`;

export function ErrorMetadata({ pageType }) {
  const vertical = useContext(VerticalContext);
  /* eslint-disable react/no-danger */
  return (
    <Head>
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={getIconLink(vertical, 'favicon.ico')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={getIconLink(vertical, 'favicon-16x16.png')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={getIconLink(vertical, 'favicon-32x32.png')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={getIconLink(vertical, 'favicon-96x96.png')}
      />
      <script
        data-test="error-page-jsonld"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: constructJSONLD({ pageType, vertical }),
        }}
      />
    </Head>
  );
  /* eslint-enable react/no-danger */
}

ErrorMetadata.propTypes = {
  pageType: PropTypes.string.isRequired,
};

export default ErrorMetadata;
